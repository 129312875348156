<template>
    <div class="cart_main">
        <van-form @submit="onSubmit">
            <van-field
                v-model="oldpassword"
                :type="oldpasswordType"
                name="原密碼"
                label="原密碼"
                placeholder="请填寫原密碼"
                autocomplete="new-password"
                :rules="[{ required: true, message: 'Please enter your current password' }]"
            >
            <template slot="right-icon">
                <span class="solts" @click="switcholdPasswordType" >
                    <van-icon name="eye" v-if="oldpasswordType ==='text'"/>
                    <van-icon name="closed-eye" v-else />
                </span>
            </template>
            </van-field>
            <van-field
                v-model="password"
                :type="passwordType"
                name="新密码"
                label="新密码"
                placeholder="请填寫新密码"
                autocomplete="new-password"
                :rules="[{ required: true, message: 'Please enter your new password' }]"
            >
                <template slot="right-icon">
                    <span class="solts" @click="switchPasswordType" >
                        <van-icon name="eye" v-if="passwordType==='text'"/>
                        <van-icon name="closed-eye" v-else />
                    </span>
                </template>
            </van-field>
            <van-field
                v-model="confirmpassword"
                :type="confirmpasswordType"
                name="確認密码"
                label="確認密码"
                autocomplete="new-password"
                placeholder="请填寫確認密码"
                :rules="[
                    { required: true, message: 'Please enter your confirm the password' },
                    { validator:passwordValidator, message: '新密码與確認密码不一致'}
                ]"
            >
                <template slot="right-icon">
                    <span class="solts" @click="changePasswordType" >
                        <van-icon name="eye" v-if="confirmpasswordType ==='text'"/>
                        <van-icon name="closed-eye" v-else />
                    </span>
                </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button round block type="info" class="submint" native-type="submit">提交</van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import {modifyPassword} from "@/api/user"
import { Notify  } from "vant";
    export default {
        data() {
            return {
                oldpassword: '',
                password: '',
                confirmpassword:'',
                passwordType:'password',
                confirmpasswordType:'password',
                oldpasswordType:'password'
            }
        },
        created(){

        },
        methods:{
            passwordValidator(val) {
                if(this.password != this.confirmpassword) {
                    return false;
                }
                return true;
            },
            onSubmit(values) {
                const validator = this.passwordValidator()
                if(validator){
                    let params = {
                        newPwd:this.password,
                        oldPwd:this.oldpassword
                    }
                    modifyPassword(params).then(res => {
                        if(res.code == 200) {
                            Notify({ type: 'success', message: '修改密码成功' });
                            this.$store.dispatch('LogOut').then(() => {
                                location.href = '/login';
                            })
                        }
                    })
                }
            },
            switchPasswordType() {
                this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
            },
            changePasswordType() {
                this.confirmpasswordType = this.confirmpasswordType === 'password' ? 'text' : 'password';
            },
            switcholdPasswordType() {
                this.oldpasswordType = this.oldpasswordType === 'password' ? 'text' : 'password';
            }
        }
    }
</script>
<style scoped>
.cart_main {
    width: 93%;
    margin: 30px auto;
    background: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
}
.submint {
    background: linear-gradient(90deg, #64B046, #AEE845);
    color: #fff;
    border: none;
    width: 90%;
    margin: 0 auto;
    font-size: 20px;
}
</style>